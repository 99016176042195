import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
Vue.config.productionTip = false;
import moment from "moment";
import * as VueGoogleMaps from "vue2-google-maps";
import { vfmPlugin } from 'vue-final-modal';
import { default as msalPlugin, msalInstance } from "vue-msal-browser";
import checkroles from '@/functions/checkroles';
import Flag from 'vue-flagpack'

import "@fortawesome/fontawesome-free/js/brands";
import "@fortawesome/fontawesome-free/js/solid";
import "@fortawesome/fontawesome-free/js/fontawesome";

import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";

Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyBgIFOsWLLmTKkJxR7iUGwS73GZ8kVQLEQ",
    libraries: "places",
  },
  installComponents: true,
});
Vue.use(Flag, {
  name: 'Flag'
})
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(vfmPlugin);
Vue.use(msalPlugin, {
  auth: {
    tenant: '66ef24e8-056c-4512-856c-7493718dd84e',
    clientId: '37823fe2-09b4-46b2-a165-973179ba19a4',
    authority: 'https://login.microsoftonline.com/66ef24e8-056c-4512-856c-7493718dd84e/',
    redirectUri: process.env.VUE_APP_ADAL_REDIRECT_URL,
    scopes: ['User.Read']
  },
  cache: {
    cacheLocation: 'localStorage'
  }
});

Vue.prototype.moment = moment;
Vue.prototype.msalInstance = msalInstance;
Vue.prototype.checkroles = checkroles;
moment.locale("nl");

new Vue({
  router,
  store,
  render: (createElement) => createElement(App)
}).$mount("#app");